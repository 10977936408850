import * as React from 'react'
import Router from 'next/router'

import { User } from '../types'

type NullableUser = User | null | undefined

type State = null | {
  user: NullableUser
}

type ContextState = {
  data: State
  onToggleEmailActive: (type: string, newValue: string) => void
  onActivityEvent: (type: string, route: { path: string, query?: any }, value?: string) => void
  changeData: (data: { user: NullableUser }) => void
}

type Props = {
  children: React.ReactNode
}

type UserUserType = {
  redirectTo?: string
  redirectIfFound?: boolean
}

const AuthContext = React.createContext<ContextState>({
  data: null,
  changeData: () => {},
  onToggleEmailActive: () => {},
  onActivityEvent: () => {},
})

export function AuthContextProvider(props: Props) {
  const { children } = props
  const [data, setData] = React.useState<State>(null)

  const shouldFetch = !data

  React.useEffect(() => {
    if (shouldFetch) {
      fetch('/api/user')
        .then((res) => res.json())
        .then(setData)
        .catch(() => setData({ user: null }))
    }
  }, [shouldFetch])

  function changeData(data: { user: NullableUser }) {
    setData(data)
  }

  async function onToggleEmailActive(listId: string, status: string) {
    try {
      setData((prevData) => {
        return {
          user: {
            ...prevData?.user,
            emails: {
              ...prevData?.user?.emails,
              [listId]: {
                ...prevData?.user?.emails?.[listId],
                status,
              },
            },
          } as NullableUser,
        }
      });

      await fetch('/api/change-user', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: data?.user?.id,
          emails: {
            ...data?.user?.emails,
            [listId]: {
              ...data?.user?.emails?.[listId],
              status,
            }
          },
        }),
      })
    } catch (e) {
      console.log(e)
    }
  }

  async function onActivityEvent(event: string, route: { path: string, query?: any }, value?: string) {
    try {
      await fetch('/api/change-user', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: data?.user?.id,
          activity: {
            event,
            slug: route.path.split('?')[0],
            value: { query: route.query, data: value || null }
          },
        }),
      })
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <AuthContext.Provider
      value={{
        changeData,
        onToggleEmailActive,
        onActivityEvent,
        data,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useUser = ({ redirectTo, redirectIfFound }: UserUserType = {}) => {
  const context = React.useContext(AuthContext)

  const data = context.data
  const user = data?.user

  const finished = Boolean(data)
  const hasUser = Boolean(user)

  React.useEffect(() => {
    if (!redirectTo || !finished) return
    if (
      (redirectTo && !redirectIfFound && !hasUser) ||
      (redirectIfFound && hasUser)
    ) {
      Router.push(redirectTo)
    }
  }, [redirectTo, redirectIfFound, finished, hasUser])

  return user
}

export const useAuthContext = () => {
  const context = React.useContext(AuthContext)
  return context
}
